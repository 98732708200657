import React, { useContext } from 'react';
import { LanguageContext } from '../components/LanguageContext';
import fr from '../assets/icons/france.png';
import eng from '../assets/icons/royaume-uni.png';

const LanguageToggleButton = () => {
    const { isFrench, setIsFrench } = useContext(LanguageContext);
    const icon = isFrench ? eng : fr;

    return (
        <button className='langageButton' onClick={() => setIsFrench(!isFrench)}>
            <img src={icon} alt='language'></img>
        </button>
    );
};

export default LanguageToggleButton;