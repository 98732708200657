import React, { useState } from 'react';
// import itchioLogo from '/itchio_logo.svg';

const Card = ({ title, image, description, count, links, roles }) => {
    const divLinks = links.flatMap((linkObj, index) =>
        Object.entries(linkObj).map(([key, value]) =>
            value !== "" ? (
                <a href={value} key={`${index}-${key}`}>
                    <i className={`fa-brands fa-${key} icon`}></i>
                </a>
            ) : null
        )
    );
    

    const divTags = roles.map((value, key) => {
        return (
            <div className='tag'>
            {value}
            </div>
        )
    });
    
    const [isCollapsed, setIsCollapsed] = useState(false);
  
    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };

    return (
        <div className='card swing-in-top-fwd' onClick={toggleCollapse} >
            <div className={`card_container ${isCollapsed ? 'isCollapsed' : 'isVisible'}`}>

                <div className='card_collapse'>
                    <div className='card_illustration'>
                        <img src={image} alt={`descriptif de ${title}`}></img>
                    </div>
                    <div className='card_title'>
                        <h2>{title}</h2>
                        <div className='card_count'>
                            <p>{count}</p>
                            <i className="fa-solid fa-user"></i>
                        </div>
                    </div>
                </div>

                <div className={`card_uncollapse`}>
                    {/* <div className='card_title'>
                        <h2>{title}</h2>
                        <div className='card_count'>
                            <p>{count}</p>
                            <i className="fa-solid fa-user"></i>
                        </div>
                    </div> */}

                    <div className='card_uncollapse_container'>
                        <div>
                            <div className='tags_container'>
                                {divTags}
                            </div>
                            <div>
                                <p>{description}</p>
                            </div>
                        </div>
                        <div className='card_links'>
                            {divLinks}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Card;
