// import background from '../assets/background_temp.jpg';
import background from '../assets/background_balls.jpg';
// import background from '../assets/background_cube.jpg';
// import background from '../assets/background_cubeintocube.jpg';
// import $ from 'jquery';
import '../styles/Welcome.css';
import React, { useEffect, useState } from 'react';
// import background from '../assets/background_balls.jpg';

function Welcome(props) {
    const claimText = '<h1>Hugo Cluzel</h1>';
    const [currentText, setCurrentText] = useState('');
    const [showFullText, setShowFullText] = useState(false);
  
    useEffect(() => {
      let characterIndex = 0;
  
      function typeCharacter() {
        if (characterIndex <= claimText.length) {
          setCurrentText(claimText.substr(0, characterIndex));
          characterIndex++;
          setTimeout(typeCharacter, Math.floor(Math.random() * 175 + 15));
        } else {
          setTimeout(() => setShowFullText(true), 1500);
        }
      }
  
      typeCharacter();
  
    }, [claimText]);
  
    return (
      <section id='welcome'>
        <div id='backgroundImage'>
          <img src={background} alt='navire sur un couché de soleil'></img>
        </div>
        <div id='welcomeTite'>
          <div id="claim-container">
            <div id='claim'>{!showFullText && currentText}<span className={!showFullText ? '' : 'off'}>_</span></div>
            <h1 id='hidden_bg' style={{ display: showFullText ? 'block' : 'none' }}>Hugo Cluzel</h1>
          </div>
          <p>{props.lang === "fr" ?  "Développeur web" : "Web Developper"}</p>
        </div>
      </section>
    );
  }

export default Welcome;
