import React, { useEffect, useRef } from 'react';
import profil from '../assets/photoProfil.png';
import '../styles/AboutMe.css';
import aboutMeJson from '../assets/data/aboutMe.json';

function AboutMe(props) {
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    useEffect(() => {

        function observeElement(ref, classes) {
            if (ref.current) {
                const observer = new IntersectionObserver(
                    ([entry]) => {
                        // Si l'élément est visible, ajoutez la classe
                        if (entry.isIntersecting) {
                            entry.target.classList.add(...entry.target.entryProps);
                            entry.target.classList.remove('disable');
                        }
                    },
                    {
                        root: null,
                        threshold: 0.5,
                    }
                );
    
                // Enregistrer les classes comme une propriété de l'élément
                ref.current.entryProps = classes;
                observer.observe(ref.current);
                // Retourne l'observateur pour nettoyage
                return observer;
            }
        }
    
        // Utilisez la fonction pour chaque élément à observer
        const observer1 = observeElement(ref1, ['animate__animated', 'animate__fadeInLeft']);
        const observer2 = observeElement(ref2, ['animate__animated', 'animate__fadeInRight']);
    
        return () => {
            observer1 && observer1.disconnect();
            observer2 && observer2.disconnect();
        };
    }, []);
    
    const text = aboutMeJson;

    return (
        <section id='aboutMe'>
            <h2 className='section_title'>
                {props.title}
            </h2>
            <div id='presentation'>
                <div id='textPresentation' className='disable' ref={ref1}>
                    <p>
                        {text[props.lang]["text"]}
                    </p>
                    <div>
                        <h2>{text[props.lang]["liens"]}</h2>
                        <div className='aboutMe_links'>
                            <a href='https://github.com/Bryndye'><i className="fa-brands fa-github icon"></i></a>
                            <a href='https://petite-bryndye.itch.io'><i className="fa-brands fa-itch-io icon"></i></a>
                            <a href='https://www.linkedin.com/in/hugo-cluzel-983aa0177/'><i className="fa-brands fa-linkedin icon"></i></a>
                        </div>
                    </div>
                </div>
                <div id='photoProfil' className='disable' ref={ref2}>
                    <img src={profil} alt='profil Hugo CLUZEL' className='photoProfil'></img>
                </div>

            </div>
        </section>
    );
}

export default AboutMe;