import '../styles/Footer.css'
function Footer() {
   return (
      <footer className='footer'>
         {/* <div className="footer_contact">
            <p>Hugo CLUZEL</p>
            <p>Tel:+33 06 60 86 06 50</p>
            <a href="mailto:cluzel.hugo1@gmail.com?subject=Besoin d'information&body=La premiere ligne du message">
            cluzel.hugo1@gmail.com
            </a>*
         </div> */}
         <p>Made by Hugo CLUZEL</p>
      </footer>
   );
}

export default Footer;