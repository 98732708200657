import React from 'react';
import '../styles/Skills.css';
import '../styles/CardsSkill.css';
import skillsJson from '../assets/data/skills.json';
import CardSkill from '../layouts/CardSkill.js';

function Skills(props) {
    const cards = skillsJson;


    return (
        <section id='myStudies'>
            <h2 className='section_title'>
                {props.title}
            </h2>
            <div className='skills_container' >
                {cards.map((card, index) => (
                    <CardSkill key={index} {...card} delay={1000 * index / 4} />
                ))}
            </div>
        </section>
    );
}

export default Skills;