import React, { useContext } from 'react';
import './styles/App.css';

import Form from './components/Form';
import MyStudies from './components/MyStudies';
import AboutMe from './components/AboutMe';
import MyExperiences from './components/MyExperiences';
import Welcome from './components/Welcome';
import Skills from './components/Skills';

import { LanguageContext } from './components/LanguageContext';
import titlesJson from './assets/data/titles.json';

const App = () => {
    const { isFrench } = useContext(LanguageContext);

    const titles = titlesJson;
    const language = isFrench ? 'fr' : 'eng';

    return (
        <main>
            <Welcome lang={language} />
            <section id='content'>
                <AboutMe  title={titles[language]['AboutMe']} lang={language} />
                <MyExperiences title={titles[language]['Experiences']} lang={language} />
                <MyStudies title={titles[language]['Studies']} lang={language} />
                <Skills title={titles[language]['Skills']} lang={language} />
                <Form title={titles[language]['Contact']} lang={language} />
            </section>
        </main>
    );
};


export default App;