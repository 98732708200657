import React from "react";
import "../styles/Form.css";
const Form = (props) => {
  //   const [email, setEmail] = useState('');

  //   const handleSubmit = (event) => {
  //         event.preventDefault();
  //         console.log(email);
  //         email.send({
  //             Host : "smtp.yourisp.com",
  //             Username : "username",
  //             Password : "password",
  //             To : 'them@website.com',
  //             From : "you@isp.com",
  //             Subject : "This is the subject",
  //             Body : "And this is the body"
  //         }).then(
  //             message => alert(message)
  //         );
  //   };

return (
    <section>
        <h2 className="section_title">{props.title}</h2>
        <div className="footer_contact">
            <p>Hugo CLUZEL</p>
            <p>Tel:+33 06 60 86 06 50</p>
            <a href="mailto:cluzel.hugo1@gmail.com?subject=Besoin d'information&body=La premiere ligne du message">
            cluzel.hugo1@gmail.com
            </a>
        </div>
        <div className="footer_links">
            <a href="https://github.com/Bryndye">
                <i className="fa-brands fa-github icon"></i>
            </a>
            <a href="https://petite-bryndye.itch.io">
                <i className="fa-brands fa-itch-io icon"></i>
            </a>
            <a href="https://www.linkedin.com/in/hugo-cluzel-983aa0177/">
                <i className="fa-brands fa-linkedin icon"></i>
            </a>
        </div>
      {/* <form onSubmit={handleSubmit}>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Votre email"
            />
            <label htmlFor="fname">First Name</label>
            <input type="text" id="fname" name="firstname" placeholder="Your name.." />

            <label htmlFor="lname">Last Name</label>
            <input type="text" id="lname" name="lastname" placeholder="Your last name.." />

            <label htmlFor="subject">Subject</label>
            <textarea id="subject" name="subject" placeholder="Write something.."></textarea>
            <button type="submit">Envoyer</button>
        </form> */}
    </section>
  );
};

export default Form;
