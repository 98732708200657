import React, { createContext, useState } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [isFrench, setIsFrench] = useState(true);

    return (
        <LanguageContext.Provider value={{ isFrench, setIsFrench }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Context permet d'entourer des components pour qu'ils aient accès à un useState par exemple