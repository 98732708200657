import React, { useEffect, useRef } from 'react';

const CardSkill = ({ title, icon, delay = 0 }) => {
    const ref1 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    const timer = setTimeout(() => {
                        entry.target.classList.add('animate__animated', 'animate__fadeInUp');
                        entry.target.classList.remove('disable');
                    }, delay);

                    return () => clearTimeout(timer);
                }
            },
            {
                root: null,
                threshold: 0.5,
            }
        );

        if (ref1.current) {
            observer.observe(ref1.current);
        }

        return () => observer.disconnect();
    }, [delay]);

    return (
        <div className='cardSkill disable' ref={ref1}>
            <div className='icon_skill'>
                <img src={icon} alt={`${title}`} />
            </div>
            <div className='text_skill'>
                <h2>{title}</h2>
            </div>
        </div>
    );
};

export default CardSkill;
