import React, { useEffect, useRef } from 'react';
import '../styles/Studies.css';
import '../styles/CardsStudy.css';
import studies_en from '../assets/data/studies_eng.json';
import studies_fr from '../assets/data/studies_fr.json';
import CardStudy from '../layouts/CardStudy.js';

function MyStudies(props) {
    const cards = props.lang === 'fr' ? studies_fr : studies_en;

    const ref1 = useRef(null);

    useEffect(() => {

        function observeElement(ref, classes) {
            if (ref.current) {
                const observer = new IntersectionObserver(
                    ([entry]) => {
                        // Si l'élément est visible, ajoutez la classe
                        if (entry.isIntersecting) {
                            entry.target.classList.add(...entry.target.entryProps);
                            entry.target.classList.remove('disable');
                        }
                    },
                    {
                        root: null,
                        threshold: 0.5,
                    }
                );

                // Enregistrer les classes comme une propriété de l'élément
                ref.current.entryProps = classes;
                observer.observe(ref.current);
                // Retourne l'observateur pour nettoyage
                return observer;
            }
        }

        // Utilisez la fonction pour chaque élément à observer
        const observer1 = observeElement(ref1, ['animate__animated', 'animate__fadeInUp']);

        return () => {
            observer1 && observer1.disconnect();
        };
    }, []);

    return (
        <section id='myStudies'>
            <h2 className='section_title'>
                {props.title}
            </h2>
            <div className='studies_container disable' ref={ref1}>
                {cards.map((card, index) => (
                    <CardStudy key={index} {...card} />
                ))}
            </div>
        </section>
    );
}

export default MyStudies;