import React, { useContext, useState, useEffect  } from 'react';
import '../styles/Header.css';
import LanguageToggleButton from '../layouts/LanguageToggleButton';
import { LanguageContext } from './LanguageContext';
import titlesJson from '../assets/data/titles.json';

function Header() {
    const { isFrench } = useContext(LanguageContext);

    const titles = titlesJson;
    const language = isFrench ? 'fr' : 'eng';

    const [isOpen, setOpen] = useState(false);
    
    useEffect(() => {
        if(window.innerWidth > 600){
            setOpen(true);
        }
        else{
            setOpen(false);
        }
    }, []); // The empty array means this useEffect runs only once on component mount

    const toggleIsOpen = () => {
        setOpen(!isOpen); 
    };
    return (
        <header>
            <div className='header_left'>
                <a href='header'>
                    <h1><i className="fa-solid fa-code"></i></h1>
                </a>
                <button id='openNav' onClick={toggleIsOpen}>
                    <i className="fa-solid fa-bars"></i>
                </button>
            </div>
            <nav className={isOpen ? "" : "disable"}>
                <button id='openNav' onClick={toggleIsOpen}>
                    <i className="fa-solid fa-bars"></i>
                </button>
                <a href='#aboutMe'>{titles[language]['AboutMe']}</a>
                <a href='#experiences'>{titles[language]['Experiences']}</a>
                <a href='#myStudies'>{titles[language]['Studies']}</a>
                <a href='#Skills'>{titles[language]['Skills']}</a>
                <a href='#form'>{titles[language]['Contact']}</a>
                <LanguageToggleButton />
            </nav>
        </header>
    );
}

export default Header;