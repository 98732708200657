import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Head() {
    const schemaOrgData = {
        "@context": "http://schema.org",
        "@type": "Portfolio",
        "name": "Hugo Cluzel",
        "image": "",
        "telephone": "0660860650",
        "description": ""
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Hugo Cluzel - Portfolio</title>
                <script src="https://kit.fontawesome.com/9716f2641e.js" crossorigin="anonymous"></script>

                {/* <meta name="twitter:card" content={image} /> */}
                <meta name="twitter:site" content="" />
                <meta name="twitter:creator" content="Hugo Cluzel" />
                <meta name="twitter:description" content="..." />
                <meta name="twitter:title" content="Portfolio Hugo Cluzel" />

                <meta name="og:title" content="Portfolio Hugo Cluzel" />
                <meta name="og:description" content="..." />
                <meta name="author" content="Hugo Cluzel" />
                {/* <meta name="og:image" content={image} /> */}

                <script type="application/ld+json">
                    {JSON.stringify(schemaOrgData)}
                </script>

                <script src="https://kit.fontawesome.com/9716f2641e.js" crossorigin="anonymous"></script>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"></link>
                <link href="https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"></link>
            
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                />
            </Helmet>
        </HelmetProvider>
    );
}

export default Head;