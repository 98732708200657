import Card from '../layouts/Card';
import projects from '../assets/data/projects.json';
import React from 'react';
import '../styles/MyExperiences.css';
import '../styles/Cards.css';

function MyExperiences(props) {
    const cards = projects[props.lang];

    return (
        <section className='experiences' id='experiences'>
            <h2 className='section_title'>
            {props.title}
            </h2>
            <div id='projects_cards'>
                {cards.map((card, index) => (
                    <Card key={index} {...card} />
                ))}
            </div>
        </section>
    );
}

export default MyExperiences;