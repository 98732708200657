import React from 'react';

const CardStudy = ({ title, ecole, annee, icon}) => {

    return (
        <div className='cardStudy'>
            <div className='icon_study'>
                <img src={icon} alt={`école de ${ecole}`}></img>
            </div>
            <div className='text_study'>
                <h2>{title}</h2>
                <p>{ecole}</p>
                <p>{annee[0]} - {annee[1]}</p>
            </div>
        </div>
    );
};

export default CardStudy;
